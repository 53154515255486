import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown, faArrowsUpToLine, faArrowsDownToLine, faTrashAlt, faPlus, faGripLines } from "@fortawesome/free-solid-svg-icons";
import "./App.css"; // Ensure CSS is present

function App() {
  const [textAreaInput, setTextAreaInput] = useState("");
  const [items, setItems] = useState([]);

  const handleTextAreaChange = (e) => {
    setTextAreaInput(e.target.value);
  };

  const splitItems = () => {
    const newItems = textAreaInput
      .split(/[\n,]+/) // Split by newline or comma
      .map((item) => item.trim()) // Trim each item
      .filter((item) => item); // Filter out any empty items
    setItems(newItems);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    setItems(newItems);
  };

  const addItem = () => {
    setItems([...items, ""]);

    // Focus on the new item input
    setTimeout(() => {
      const itemInputs = document.querySelectorAll(".item-input");
      itemInputs[itemInputs.length - 1].focus();
    }, 160);
  };

  const handleItemChange = (index, value) => {
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  };

  const moveItem = (index, direction) => {
    const newItems = [...items];
    const swapIndex = index + direction;

    if (swapIndex >= 0 && swapIndex < newItems.length) {
      [newItems[index], newItems[swapIndex]] = [newItems[swapIndex], newItems[index]];
      setItems(newItems);
    }
  };

  const moveItemToTop = (index) => {
    const newItems = [...items];
    const [item] = newItems.splice(index, 1);
    newItems.unshift(item);
    setItems(newItems);
  };

  const moveItemToBottom = (index) => {
    const newItems = [...items];
    const [item] = newItems.splice(index, 1);
    newItems.push(item);
    setItems(newItems);
  };

  const handleDeleteItem = (index) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      // Proceed with deletion
      deleteItem(index);
    }
  };

  const deleteItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const outputItems = () => {
    setTextAreaInput(items.join("\n"));
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(textAreaInput).then(() => {
    }).catch(err => {
      console.error("Failed to copy text: ", err);
    });
  };

  return (
    <div className="app">
      <h1>Shopping List App</h1>
      <textarea
        value={textAreaInput}
        onChange={handleTextAreaChange}
        placeholder="Enter your shopping list, separated by commas or new lines..."
        rows="5"
        cols="50"
      ></textarea>

      <div className="main-button-group">
        <button onClick={splitItems}>Split Items</button>
        <button onClick={outputItems}>Output List</button>
        <button onClick={handleCopyToClipboard}>Copy to Clipboard</button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-area">
          {(provided) => (
            <div
              className="drop-area"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                  {(provided) => (
                    <div
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <span className="drag-handle">
                        <FontAwesomeIcon icon={faGripLines} />
                      </span>
                      <input
                        type="text"
                        value={item}
                        onChange={(e) => handleItemChange(index, e.target.value)}
                        className="item-input"
                      />
                      <div className="button-group">
                        <button className="icon-button" onClick={() => moveItemToTop(index)}>
                          <FontAwesomeIcon icon={faArrowsUpToLine} />
                        </button>
                        <button className="icon-button" onClick={() => moveItem(index, -1)}>
                          <FontAwesomeIcon icon={faArrowUp} />
                        </button>
                        <button className="icon-button" onClick={() => moveItem(index, 1)}>
                          <FontAwesomeIcon icon={faArrowDown} />
                        </button>
                        <button className="icon-button" onClick={() => moveItemToBottom(index)}>
                          <FontAwesomeIcon icon={faArrowsDownToLine} />
                        </button>
                        <button className="icon-button delete" onClick={() => handleDeleteItem(index)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <button onClick={addItem} className="add-item-button">
        <FontAwesomeIcon icon={faPlus} /> Add Item
      </button>
    </div>
  );
}

export default App;
